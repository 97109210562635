import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './providers/language.service';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AccountService } from './providers/account.service';
import { HostnameService } from './providers/hostname.service';
import { ChartDataService } from './providers/chart.service';
import { LearnerService } from './providers/learner.service';
import { StaffService } from './providers/staff.service';
import { StudentService } from './providers/student.service';
import { initializeKeycloak } from './helper/auth.init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { OrgService } from './providers/org.service';
import { UtilitieAddressService } from './providers/utilities-address.service';
import { FileService } from './providers/file.service';
import { FilesDownloadedService } from './providers/files-downloaded.service';

import { OrganizationService } from './providers/organization.service';
import { FileInternalService } from './providers/file-internal/file-internal.service';
import { FileInternalReceiveService } from './providers/file-internal/file-internal-receive.service';
import { FileInternalSendService } from './providers/file-internal/file-internal-send.service';
import { VanPhongCodesService } from './providers/van_phong_codes.service';
import { ProcessUploadStatusService } from './providers/process_upload_status.services';
import { AccountAvatarService } from './providers/avatar.service';
import { GeneralDataConvertService } from './providers/general-data-convert.service';
import { GeneralDataService } from './providers/general-data.service';
import { BaoCaoDataService } from './providers/bao-cao-data/bao-cao-data.service';
import { RolesPermissionService } from './providers/authentication/roles.service';
import { MenuItems } from './providers/authentication/menu-items.service';

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';
import { FullComponent } from './layouts/full/full.component';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './layouts/accordion';
import { KhtcBctcTT107Service } from './providers/bao-cao-ban-khtc-bctc/khtc-bctc.service';
import { BcListKhtcBcqtrDataService } from './providers/bao-cao-ban-khtc-bcqtr/bao-cao.bc-list.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from './user/shared-component/datatable-single-header/custom-paginator.util';
import { CookieService } from 'ngx-cookie-service';
import { ErrorPageForMainComponent } from './authentication/error-main-component/error.component';

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.19b316c5762910f7b31d8cb3a4b0b87b.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true,
};

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        VerticalAppHeaderComponent,
        SpinnerComponent,
        AppBlankComponent,
        VerticalAppSidebarComponent,
        AppBreadcrumbComponent,
        HorizontalAppHeaderComponent,
        HorizontalAppSidebarComponent,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,

        ErrorPageForMainComponent,
    ],
    imports: [
        KeycloakAngularModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        BrowserModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        PerfectScrollbarModule,
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: MatPaginatorIntl,
            useFactory: (translate: TranslateService) => {
                const service = new PaginatorIntlService(translate);
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService],
        },

        CookieService,

        DatePipe,
        LanguageService,
        AccountService,
        HostnameService,
        ChartDataService,

        LearnerService,
        StaffService,
        StudentService,
        OrgService,
        UtilitieAddressService,
        FileService,
        FilesDownloadedService,

        OrganizationService,

        FileInternalService,
        FileInternalSendService,
        FileInternalReceiveService,
        GeneralDataService,
        VanPhongCodesService,

        ProcessUploadStatusService,
        AccountAvatarService,

        GeneralDataConvertService,

        // BAO CAO TCCB
        BaoCaoDataService,

        // BAO CAO KHTC BCTC
        KhtcBctcTT107Service,
        BcListKhtcBcqtrDataService,

        // ROLES
        RolesPermissionService,
        MenuItems,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
